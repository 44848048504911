<template>
  <b-modal id="detailsModel" size="lg" title="Notification Email Details">
    <b-table
      :fields="fields"
      :items="emails"
      bordered
      class="break"
      empty-text="No Emails Found"
      fixed
      hover
      id="emails_table"
      responsive
      show-empty
      small
      striped
    >
      <template v-slot:cell(email_type)="data">
        {{ data.item.type }}
      </template>
      <template v-slot:cell(sent_at)="data">
        <strong>{{ data.item.createdAt.humanReadable }}</strong> at
        <em>{{ data.item.createdAt.dateTime }}</em>
      </template>
      <template v-slot:cell(read_at)="data">
        <span v-if="data.item.readAt">
          <strong>{{ data.item.readAt.humanReadable }}</strong> at
          <em>{{ data.item.readAt.dateTime }}</em>
        </span>
      </template>
      <template v-slot:cell(clicked_at)="data">
        <span v-if="data.item.clickedAt">
          <strong>{{ data.item.clickedAt.humanReadable }}</strong> at
          <em>{{ data.item.clickedAt.dateTime }}</em>
        </span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  name: "EmailsDetail",
  data() {
    return {
      fields: [
        {
          key: "email_type",
          label: "Email Type",
          thStyle: {
            width: "200px"
          }
        },
        {
          key: "sent_at",
          sortable: true,
          thStyle: {
            width: "150px"
          }
        },
        {
          key: "read_at",
          sortable: true,
          thStyle: {
            width: "150px"
          }
        },
        {
          key: "clicked_at",
          sortable: true,
          thStyle: {
            width: "150px"
          }
        }
      ],
      emails: []
    };
  },
  mounted() {
    this.$root.$on("selected_user", user_id => {
      this.$store
        .dispatch("emailDetails", { user_id })
        .then(response => {
          if (!response.data.error) this.emails = response.data.notifications;
          else this.emails = [];
        })
        .catch(error => {
          if (error) {
            alert(error);
          }
        });
    });
  },
  beforeDestroy() {
    this.emails = [];
  }
};
</script>

<style scoped>
.break {
  max-height: 55vh;
  overflow: scroll;
}
</style>
